/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { Fragment, useEffect } from "react";
import { Box, Flex, Heading, Text, Link } from "rebass";
import Hero from "../../../components/Hero";
import ProjectTitleListing from "../../../components/ProjectTitleListing";
import ProjectFadeInTitle from "../../../components/ProjectFadeInTitle";
import BackToTop from "../../../components/BackToTop";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../layouts/";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ThemeColorSetter from "../../../components/ThemeColorSetter"; 
import BookScene from "../../../components/BookScene";
import CompanyProjects from "../../../components/CompanyProjects";
//* import Card from "../../../components/Card";

import Carousel from "../../../components/Carousel";
import img1 from "../../../img/ds/ds-zeroheight-lapierre-cover.png";
import img2 from "../../../img/ds/ds-zeroheight-lapierre-colors.png";
import img3 from "../../../img/ds/ds-zeroheight-design-tokens.png";
import img4 from "../../../img/ds/ds-zeroheight-lapierre-product.png";
import img5 from "../../../img/ds/ds-zeroheight-lapierre-product-line-hero.png";
import SourceOfTruth from "../../../icons/SourceOfTruth";
import ForEveryone from "../../../icons/ForEveryone";
import LackOfCoherence from "../../../icons/LackOfCoherence";
import DsComponents from "../../../icons/DsComponents";

function Project() {
  const context = useThemeUI();
  const { theme } = context;
  const projectBgColor = theme?.colors?.accellProjectBgDs as string;
  const projectTextColor = theme?.colors?.accellProjectTextDs as string;
  const themeColor = theme?.colors?.accellProjectBgDs as string;
  const themeColorBg = theme?.colors?.background as string;

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };

    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);
  return (
    <Layout>
      <ThemeColorSetter color={themeColor} colorBg={themeColorBg}/>
      <main>
        <Header bgColor={projectBgColor} textColor={projectTextColor} />
        <Hero
          projectType={"Case study"}
          productType={"eCommerce, Multi-brand, Design System"}
          title={"Design"}
          titleRow={"System(s)"}
          titleClassName={"uppercase"}
          subtitle={"Achieving the best user experience while promoting best design practices, trough collaboration across disciplines."}
          projectBg={projectBgColor}
          projectText={projectTextColor}
          shortDesc={
            <Fragment>
              Our goal for the project was to work agile and efficient in a
              cross functional team. <br />A Design System offers structure,
              standards and build-in visual consistency by providing a way of
              working across disciplines, where design plays more the role of a
              facilitator. It promotes <strong>best practices</strong> in
              achieving the best user experience{" "}
              <strong>while working together</strong>.{" "}
            </Fragment>
          }
          year={"2020"}
          role={
            <Fragment>
              Strategy <br /> Planning <br />
              Design + Execution <br /> Support
            </Fragment>
          }
          company={"ACCELL"}
          roleDesc={
            <Fragment>
              I led the efforts in the creation of multiple design systems, from
              strategizing and planning to execution, for 3 brands (Raleigh,
              Lapierre, Haibike). In addition, I worked alongside a UX lead
              researcher, junior designer, content editors and strategists,
              brand managers, SEO specialists, developers and a product owner.
              <br />
              Daily operations included working agile in 2 weeks sprints.
            </Fragment>
          }
          disciplines={
            <Fragment>
              Branding &mdash; Content &mdash; Design &mdash; Development
              &mdash; SEO
            </Fragment>
          }
          challenge={
            <Fragment>
              We had one primary objective — to deliver a headless eCommerce
              platform to serve the entire portfolio of brands. Raleigh was
              chosen to be first to test the concept.
              <br />
              <strong>
                Our small team was under extreme pressure to move fast.
              </strong>{" "}
              We were tasked to deliver an entire new platform, build from
              scratch, within 4 months. <br />
              The combination of a fixed deadline, and the capacity within the
              design team meant I needed to get the Design System going all by
              myself. Prioritization was key to delivering on time.{" "}
            </Fragment>
          }
          component={
            <BookScene
              coverColor={"#f0c844"}
              textColor={"silver"}
              urlTexture={"/models/textures/ds-book-cover.jpg"}
            />
          }
        />

        <Flex
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          id="project-content"
        >
          <Box width={[1 / 1, 1 / 1, 7 / 12]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"Design"}
              word1ClassName={"outline"}
              word3={"for"}
              className={"uppercase"}
              word4={"Teams"}
              word4ClassName={"uppercase"}
            />
          </Box>
          <Box width={[1 / 1, 1 / 1, 5 / 12]} variant="containerBigTitleImg">
            <Heading as="h3" variant="catHeading">
              Join the Party
            </Heading>
            <ForEveryone />
            <Text as="span" variant="caption">
              Design Systems bring clarity in multi-disciplinary teams. This
              means more time to be productive.
            </Text>
          </Box>
          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              User story
            </Heading>
            <Text as="p" pb={[3, 4]} mb={[4, 5, 6]} variant="paragraphXL">
              <strong>As a</strong> designer / developer / content editor /
              marketeer / agency <br />
              <strong>I want to</strong> quickly have access to the Visual
              Language / UI Components, Object Components, Patterns, Resources
              and Guidelines
              <br />
              <strong>So that</strong> I know which rules to apply in order to
              design / build / create while keeping consistency for the brand
              and stay true to its archetype.
            </Text>
            <Heading as="h2" variant="bigTitleXS">
              What
            </Heading>
            <Text as="p" mb={[5, 6]}>
              The Design System &mdash; a systematic approach to design.
              <strong>
                {" "}
                It is a definition of elements such as typography, colors, UI
                components, codes and guidelines
              </strong>{" "}
              that we use repeatedly and consistently to build our websites and
              products across channels and mediums. It provides a better way of
              working.
            </Text>
            <Heading as="h2" variant="bigTitleXS">
              Why
            </Heading>
            <Heading as="h3" variant="quote">
              Inconsistency in user experience across digital products can cause
              a decrease in learnability and confusion.
            </Heading>
            <Text as="p" mb={[3, 4]}>
              A Design System brings order and consistency to our digital
              products. They help to protect our brand, improve user experience
              and time to market and brings efficiency of how we design and
              build products.
              <br />
              It is a <strong>source of truth</strong> and a system of record
              for our design decisions.
              <br />
              It <strong>holds us to high standards</strong>, keep teams on the
              same page, and help to onboard new team members.
              <br />
              It <strong>documents</strong> the why, when, where, and how.
              <br />
            </Text>
            {/* <Text as="p" mb={[5,6]}>
            A design system can also help improving on the long term the way we
            design and develop digital products which can lead to less
            discussions and improve speed of development.
            <br />A design system should be the{" "}
            <strong>single source of truth</strong> that groups all the elements
            that allow teams to design, realize and develop a product.
          </Text> */}
            <Heading as="h2" variant="bigTitleXS" mt={[3, 4, 5]}>
              Who
            </Heading>
            <Text as="p">
              The design system is relevant for everyone in the creative and
              development space, such as:
              <br />
            </Text>
            <ul>
              <li>Designers</li>
              <li>Developers & Engineers</li>
              <li>Marketeers</li>
              <li>Content editors</li>
              <li>Creative agencies</li>
            </ul>
            <Heading as="h2" variant="bigTitleXS" mt={[3, 4, 5]}>
              Clear Benefits
            </Heading>
            <ul>
              <li>
                <strong>Design & develop</strong> in a more structured way
              </li>
              <li>
                <strong>Focus</strong> on solving real user problem instead of
                preventing or researching inconsistencies
              </li>
              <li>
                <strong>Faster</strong> development process, better code &amp;
                quality and ensures scalability
              </li>
            </ul>

            <Text as="p" mb={[5, 6]}>
              {/* A Design System is not just about establishing a resource or
            creating a catalogue of components or deliverables but it’s more
            like a mindset, a better way of working together through
            collaboration.
            <br /> */}
              In combination with the right audiences, it can promote a company
              wide design mentality which is useful not just for the creation of
              product design but can also influence the process of creating and
              marketing a product.
              <br />
              <br />
              <strong>
                Separated teams: brand, marketing, product, development should
                be included as much as possible to ensure that everyone is
                working toward a unified brand and product.
              </strong>
            </Text>
            <SourceOfTruth />
          </Box>
        </Flex>
        {/* Lack of Consistency */}
        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box width={[1 / 1, 1 / 1, 7 / 12]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"Lack of"}
              word1ClassName={"outline"}
              word3={"Consitency"}
              className={"uppercase"}
            />
          </Box>
          <Box width={[1 / 1, 5 / 12]} variant="containerBigTitleImg">
            <Heading as="h3" variant="catHeading">
              Clarity
            </Heading>
            <LackOfCoherence />
            <Text as="span" variant="caption">
              Achieving consistency is a milestone, but{" "}
              <strong>Design Systems should aim for coherence.</strong>{" "}
              Consistency aims for making elements uniform. Coherence aims for
              clarity.
            </Text>
          </Box>

          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              Start with Intent
            </Heading>
            <Text as="p" mb={[5, 6]}>
              The design team had a solid background in building and optimizing
              web based platforms and one of the most common issues agreed upon
              was inconsistency regarding visual representations of the same
              elements in user interfaces of digital products.
            </Text>

            <Heading as="h2" variant="bigTitleXS">
              Customer
            </Heading>
            <Text as="p" mb={[5, 6]}>
              Inconsistency results in{" "}
              <strong>difficulty for user to (learn how to) navigate</strong>{" "}
              any digital product, thus there is a risk of decrease of trust in
              the company behind the digital product.
            </Text>

            <Heading as="h2" variant="bigTitleXS">
              Content Editor
            </Heading>
            <Text as="p" mb={[5, 6]}>
              In a traditional approach the content is designed and saved in
              pages within the CMS. This results in{" "}
              <strong>content not being re-usable across channels</strong>.
              Therefore content editors have to entry the same content
              repeatedly.
            </Text>

            <Heading as="h2" variant="bigTitleXS">
              Design &mdash; Development
            </Heading>
            <Text as="p" mb={[5, 6]}>
              There is a <strong>gap between</strong> the way{" "}
              <strong>developers</strong> think & develop and the way{" "}
              <strong>designers</strong> think and design. A design system
              solves this issue by providing an ecosystem of objects components
              that relate to each other, by enforcing a single source of truth.
            </Text>
          </Box>
        </Flex>

        {/* The OOUX The Solution   */}
        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box width={[1 / 1, 1 / 1, 8 / 12]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"The"}
              word2={"OOUX"}
              word2ClassName={"outline"}
              word3={"The"}
              word3ClassName={"outline"}
              word4={"Solution"}
              className={"uppercase"}
            />
          </Box>
          <Box width={[1 / 1, 4 / 12]} variant="containerBigTitleImg">
            <Heading as="h3" variant="catHeading">
              Team OOUX
            </Heading>
            <Text as="span" variant="caption">
              <strong>
                "Align, co-create, and reduce rework with Object-Oriented UX
                (OOUX)
              </strong>{" "}
              &mdash; <strong>Sophia V. Prater</strong>. Within our team it was
              introduced, promoted and evangelized by Kamil, our UX Lead.{" "}
            </Text>
            <Box variant="wrapperImg">
              <StaticImage
                src="../../../img/ds/ds-DanaPavlichko-SophiaVoychehovski-OOUX-Final.png"
                alt="Design System - OOUX"
              />
            </Box>
            <Text as="span" variant="caption">
              llustration by{" "}
              <Link
                variant="aLink"
                href="https://alistapart.com/author/danaleeee/"
                target="_blank"
                rel="noopener norefferer"
              >
                Dana Pavlichko
              </Link>
            </Text>
          </Box>
          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              Object Oriented UX
            </Heading>
            <Text as="p" pb={[3, 4]} mb={[2, 3]}>
              As opposite to visualization of pages in images, which can lead to
              slow development and inconsistency of the end result versus the
              initial design the{" "}
              <strong>
                <Link
                  variant="aLink"
                  href="https://alistapart.com/article/object-oriented-ux/"
                  target="_blank"
                  rel="noopener norefferer"
                  mb={"0px"}
                >
                  Object Oriented UX (OOUX)
                </Link>
              </strong>{" "}
              is a solution to end frustration for both designers and
              developers. OOUX is a framework and a way of thinking that
              supports researching, designing, developing and maintaining a
              digital product.
            </Text>
            <Heading as="h6" variant="catHeading">
              This is the way
            </Heading>
            <Flex alignItems="center" mb={[4, 5]}>
              <Box width={[1 / 2]}>
                <Text as="p" variant="paragraphXL">
                  <strong>Components first.</strong>
                  <br />
                  <strong>Content first.</strong>
                  <br />
                  <strong>Mobile first.</strong>
                </Text>
              </Box>
              <Box width={[1 / 2]}>
                <Heading as="h3" variant="bigTitle" textAlign={"right"}>
                  1 <sup>st</sup>
                </Heading>
              </Box>
            </Flex>
            <Text as="p">By working object-oriented we ensure that:</Text>
            <ul>
              <li>
                we keep simplicity and reduce complexity as we do not introduce
                unnecessary new design elements
              </li>
              <li>
                we are able to easily improve our UX by iterating on our
                existing objects without impacting the rest or creating clutter
              </li>
              <li>
                <strong>most important:</strong> build brands using existing
                objects and iterating on them by adapting the styling of the
                objects to the brand identity
              </li>
            </ul>
            <Text as="p">
              The concept of OOUX has been translated to our design system by
              using <strong>Object Components</strong>. You could see them as
              Lego bricks (objects) we use to build up user interfaces.{" "}
            </Text>
          </Box>
        </Flex>
        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box variant="containerTxt">
            <Flex flexWrap="wrap">
              <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                <Heading as="h3" variant="catHeading">
                  OOUX
                </Heading>
                <Text as="p" pr={[0, 2, 4]}>
                  Understand and design the system objects
                </Text>
              </Box>
              <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                <Heading as="h3" variant="catHeading">
                  CTA Inventory
                </Heading>
                <Text as="p" pr={[0, 2, 4]}>
                  Decide the actions on objects
                </Text>
              </Box>
              <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                <Heading as="h3" variant="catHeading">
                  Interaction
                </Heading>
                <Text as="p">Design how users take actions</Text>
              </Box>
            </Flex>
            <Heading as="h3" variant="catHeading" mt={[3, 4, 5]}>
              System of Objects
            </Heading>
            <Text as="span" pb={[3, 4, 5]}>
              Below a very basic example on how we started brainstorming for
              objects. Objects can be extracted from product goals. To each
              object we defined elements such as core content, and nested
              objects. As a final step we prioritize the elements and associate
              actions.{" "}
            </Text>
            <br />
            <br />
            <StaticImage
              src="../../../img/ds/ds-OOUX-post-its.png"
              alt="Object Oriented approach"
            />
          </Box>
        </Flex>
        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box width={[1 / 1]} variant="containerTxt">
            <Heading as="h2" variant="bigTitleXS">
              Product Object <br /> in our Design System
            </Heading>
            <Text as="p">
              The core products of the each brand's offering. We use this for
              bikes, equipment, parts.
            </Text>
          </Box>
          <Box width={[1 / 1]}>
            <Flex flexWrap="wrap" justifyContent="center">
              <Box maxWidth="328px" pr={[0, 0, 3, 4]}>
                <Heading as="h3" variant="catHeading">
                  Small
                </Heading>
                <Box mb={[1]} variant="wrapperImg">
                  <StaticImage
                    src="../../../img/ds/ds-OProductS.jpg"
                    alt="Product Object - smallest representation"
                  />
                </Box>
                <Text as="span" variant="caption">
                  Used as a confirmation when adding a product to the basket.
                </Text>
              </Box>
              <Box maxWidth="328px" pr={[0, 0, 3, 4]}>
                <Heading as="h3" variant="catHeading">
                  Medium
                </Heading>
                <Box mb={[1]} variant="wrapperImg">
                  <StaticImage
                    src="../../../img/ds/ds-OProductM.jpg"
                    alt="Product Object - medium representation"
                  />
                </Box>
                <Text as="span" variant="caption">
                  Used in the basket as well as in the cross sell listing.
                </Text>
              </Box>
              <Box maxWidth="528px" pr={[0, 0, 3, 4]}>
                <Heading as="h3" variant="catHeading">
                  Large
                </Heading>
                <Box mb={[1]} variant="wrapperImg">
                  <StaticImage
                    src="../../../img/ds/ds-OProductL.jpg"
                    alt="Product Object - large representation"
                  />
                </Box>
                <Text as="span" variant="caption" sx={{ maxWidth: "none" }}>
                  Used most commonly across the orientation pages, product
                  listing pages, series pages. Always lists a product, be it a
                  bike, an equipment or a part.
                </Text>
              </Box>

              <Box maxWidth="528px">
                <Heading as="h3" variant="catHeading">
                  Full
                </Heading>
                <Box mb={[1]} variant="wrapperImg">
                  <StaticImage
                    src="../../../img/ds/ds-OProduct-full.jpg"
                    alt="Product Object - full representation"
                  />
                </Box>
                <Text as="span" variant="caption" mb={[2, 3]}>
                  Full representation of the product object where all core
                  content and nested objects are present. It is so large that
                  for presentation purposes I only show ~15% of it.
                </Text>
              </Box>
            </Flex>
          </Box>
        </Flex>

        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading" pb={[2]}>
              Clear &amp; basic
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              The Structure
            </Heading>
            <Text as="p">
              The design system follows the Object Oriented (OOUX) approach. To
              reduce friction during collaboration, components are semanticaly
              named based on known terms within our team.
            </Text>
            <ul>
              <li>
                <strong>Overview</strong> &mdash; an introduction to our choice
                for Object Oriented UX, the brand, editorial and visual
                guidelines
              </li>
              <li>
                <strong>Visual Language</strong> &mdash; helps us distinguish
                between every element and every experience designed by / for
                Lapierre and helps the brand to differentiate from its
                competitors.
              </li>
              <li>
                <strong>UI Components</strong> &mdash; key building blocks, each
                component was design to solve a specific UI problem and work
                harmoniously together.
              </li>
              <li>
                <strong>Object Components</strong> &mdash; For all our brands we
                apply the principles of OOUX. It is a framework in which we
                think about core content and data as objects instead of
                artifacts we would normally use such as pages.{" "}
              </li>
              <li>
                <strong>Patterns</strong> &mdash; best practice solutions for
                how a user achieves a goal. They show reusable combinations of
                components, objects and templates that address common user
                objectives with sequences and flows.
              </li>
              <li>
                <strong>Guidelines</strong> &mdash; What you need to get started
                with designing and developing, libraries and assets.
              </li>
            </ul>
            <Box variant="wrapperImg">
              <StaticImage
                src="../../../img/ds/ds-mind-map.jpg"
                alt="Anatomy of a Design System"
              />
            </Box>
          </Box>
        </Flex>

        {/* DS Cards */}
        {/* <Flex
        flexWrap="wrap"
        py={[3, 4]}
        variant="container"
        justifyContent="center"
      >
        <Card
          title={"Overview"}
          desc={
            "I'm here to on board you. Tell you a bit about me and my values."
          }
        />
        <Card
          title={"Visual Language"}
          desc={"I'm giving character. The Look & Feel."}
        />
        <Card
          title={"UI Components"}
          desc={"I'm the box that holds the legos. You can build anything."}
        />
        <Card title={"Object Components"} desc={"I'm "} />
        <Card title={"Patterns"} desc={"I'm "} />
        <Card title={"Guidelines"} desc={"I'm "} />
      </Flex> */}

        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading" pb={[2]}>
              Science Club meets Art Club
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Play by the Same Rules
            </Heading>
            <Text as="p" mb={[1, 2, 3]}>
              <strong>
                Design systems exist to handle and encourage change.
              </strong>{" "}
              However this can only happen if everyone plays by the same rules
              based on collaboration and partnership. The challenge of design
              systems is to find the balance between scalability and creative
              freedom.
            </Text>
            <Heading as="h4" mt={[2, 3, 4]} fontSize={[4, 5]}>
              Summary of achievements
            </Heading>
            <ul>
              <li>
                opened the Design system for feedback and input from all
                disciplines and as a result components perform better.
              </li>
              <li>
                I've invested time in{" "}
                <strong>labeling everything precisely</strong>. A designer or a
                developer can quickly understand and locate what they’re looking
                for, solely by the name. The Object Oriented UX approach from
                the design system and naming conventions are followed across
                disciplines.
              </li>
              <li>
                I've designed <strong>flexible building blocks</strong> right at
                your fingertips. Implementing a new module with a heading, body
                copy and a call-to-action(CTA) is as easy as grabbing those
                components from the design system.
              </li>
              <li>
                Everything was designed to be component based so that{" "}
                <strong>commonly paired elements can be fully assembled</strong>{" "}
                in sections using the same basic elements from our design
                system.{" "}
              </li>
            </ul>
            <Heading as="h3" variant="catHeading" mt={[3, 4, 5, 6]}>
              Lesson learned
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Happy Flow
            </Heading>
            <Text as="p">
              Unfortunately we had to{" "}
              <strong>leave out the most-wanted feature</strong>, the connection
              between Sketch and Storybook. This is a must-do to ensure the
              components are always in sync.{" "}
              <strong>
                The source-of-truth needs to stay up-to-date across tools
              </strong>
              . By not evangelizing the importance of this connection within
              your organization you’ll greatly increase time spent on
              maintenance. <br />
              During development there's a process loop of ideation where Sketch
              components can loose their status of "truth".
            </Text>
            <DsComponents />
            <Text as="span" variant="caption" maxWidth="none">
              <strong>Sketch/Figma</strong>(Design) &mdash;{" "}
              <strong>Code</strong>
              (Development) &mdash; <strong>Storybook</strong>(UI Components
              directly from code). Storybook components can be and should be
              used in Sketch(or Figma).
            </Text>
          </Box>
        </Flex>
        {/* Scaling for Multi-Brand */}
        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <ProjectFadeInTitle
            word1={"Scaling"}
            word1ClassName={"uppercase"}
            word3={"for"}
            word3ClassName={"outline uppercase"}
            word4={"multi-brand"}
            word4ClassName={"uppercase"}
          />
          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              Design to scale
            </Heading>
            <Heading as="h3" variant="bigTitleXS">
              Keeping Up with Demands
            </Heading>
            <Text as="p" mb={[1, 2, 3]}>
              While the design system offers the same set of components, to
              expand it across multiple brands requires the use of:
            </Text>
            <ul>
              <li>
                <strong>Design tokens</strong> — global and brand specific.
                These can represent anything defined by design: a color as a RGB
                value, an opacity as a number, an animation ease as Bezier
                coordinates.
              </li>
              <li>
                <strong>Brand configuration system</strong> — One code different
                results. Design Tokens take care of the styling but there are
                situations when layout needed to be handled differently and
                accordingly to a brand needs.
              </li>
              <ul>
                <li>
                  <strong>Layout changes</strong> — sport vs. lifestyle brands
                </li>
                <li>
                  <strong>Object Components</strong> — specific content
                </li>
                <li>
                  <strong>Payment methods</strong> — localization specific
                </li>
                <li>
                  <strong>Distribution</strong> — dealers, click & collect
                </li>
                <li>
                  <strong>eCommerce</strong> — only certain brands, at times
                  even only certain countries wanted to sell online
                </li>
                <li>
                  <strong>Theming</strong> — light/dark mode
                </li>
              </ul>
            </ul>
            <Heading as="h3" variant="catHeading" mt={[3, 4, 5]}>
              Theming
            </Heading>
            <Text as="p" mb={[3]}>
              The brand configuration system acts like a switch by changing the
              values within the design token.{" "}
            </Text>
            <Box variant="wrapperImg">
              <StaticImage
                src="../../../img/ds/ds-brand-config-mind-map.jpg"
                alt="Design System and Brand Configuration System"
              />
            </Box>
          </Box>
          <Box width={[1 / 1]}>
            <Flex flexWrap="wrap">
              <Box width={[1 / 1, 1 / 2]} mb={[3, 0]} pr={[0, 4]}>
                <StaticImage
                  src="../../../img/ds/ds-raleigh-home.jpg"
                  alt="Lapierre homepage"
                />
              </Box>
              <Box width={[1 / 1, 1 / 2]} pl={[0, 4]}>
                <StaticImage
                  src="../../../img/ds/ds-lapierre-home.jpg"
                  alt="Lapierre homepage"
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
        {/* Win Win Design System */}
        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <ProjectFadeInTitle
            word1={"Win"}
            word1ClassName={"outline uppercase"}
            word2={"Win"}
            word2ClassName={"uppercase"}
            word3={"Design"}
            word3ClassName={"outline uppercase"}
            word4={"System"}
            word4ClassName={"uppercase"}
          />
          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              In conclusion
            </Heading>
            <Text as="p" mb={[5, 6]}>
              <strong>Design Systems are like ecosystems</strong> &mdash; they
              thrive within an environment where teams from all involved
              disciplines work together to form great user experiences. Building
              one is fairly easy to begin with. Start with the most basic of
              design principles and all the rules that come with the best
              practices will follow. It can expand pretty quickly to create an
              entire new world set by rules of guidance and usage, but always
              remember and never forget that{" "}
              <strong>the devil is in the details</strong>.
            </Text>
            <Heading as="h3" variant="bigTitleXS">
              WIN for Customer
            </Heading>
            <Text as="p" mb={[5, 6]}>
              The experience of a more consistent UI, thus making it easier to
              learn, navigate, find and complete a task, be it either to find
              information or make a purchase.
            </Text>

            <Heading as="h3" variant="bigTitleXS">
              WIN for Team(s)
            </Heading>
            <Text as="p">
              Easier and faster to design, develop and maintain while keeping
              consistency. This{" "}
              <strong>
                helps business on focusing on actual problem solving
              </strong>{" "}
              for customers and as a result yield better comercial results as
              well as lower costs.
            </Text>
          </Box>
        </Flex>

        <Carousel img1={img1} img2={img2} img3={img3} img4={img4} img5={img5} />

        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              The End
            </Heading>
            <Heading as="h3" variant="bigTitleXS">
              Deliverables
            </Heading>
            <ul>
              <li>
                Report on concept and structure in Atlassian Confluence (how to
                get things started)
              </li>
              <li>Atlassian JIRA Roadmap</li>
              <li>UI Library in Sketch</li>
              <li>Documentation</li>
              <li>Code (for UI Components)</li>
              <li>Assets</li>
              <li>Design Tokens</li>
              <li>Prototyping</li>
              <li>
                Zeroheight for&nbsp;
                <Link
                  variant="aLink"
                  href="https://zeroheight.com/2a0d91f6c/p/738ed7-raleigh"
                  target="_blank"
                  rel="noopener norefferer"
                  mb={"0px"}
                >
                  Raleigh
                </Link>
                &nbsp;&amp;&nbsp;
                <Link
                  variant="aLink"
                  href="https://zeroheight.com/6ebd1dfb8/p/9979d8-lapierre"
                  target="_blank"
                  rel="noopener norefferer"
                  mb={"0px"}
                >
                  Lapierre
                </Link>
              </li>
            </ul>
            <Heading as="h2" variant="bigTitleXS" mt={[3, 4, 5]}>
              Personal Note
            </Heading>
            <Text as="p">
              When designing components in your tool of choice, be it Sketch or
              Figma, it's important to consider how other designers operate,
              utilize and want their component to behave. This responsibility
              expands when working with developers. Components need to be
              properly named, thus friction is reduced during collaboration.
              <br />
              While design systems need to be documented, they also need to
              allow enough flexibility so that designers don’t feel that their
              creativity is hindered.
              <br />
              <strong>
                In hindsight I should have promoted the Design System more.
              </strong>
            </Text>
            <Heading as="h3" variant="catHeading" mt={[3, 4, 5]}>
              Out there
            </Heading>
            <Link
              variant="buttons.primary"
              href="https://zeroheight.com/6ebd1dfb8/p/9979d8-lapierre"
              target="_blank"
              rel="noopener norefferer"
              py={[3]}
              my={[3, 4]}
              mr={[3]}
            >
              View Lapierre
            </Link>

            <Link
              variant="buttons.primary"
              href="https://zeroheight.com/2a0d91f6c/p/738ed7-raleigh"
              target="_blank"
              rel="noopener norefferer"
              py={[3]}
              my={[3, 4]}
            >
              View Raleigh
            </Link>
          </Box>
        </Flex>
        <CompanyProjects title="ACCELL" />
        <ProjectTitleListing />
        <BackToTop />
        <Footer />
      </main>
    </Layout>
  );
}
export default Project;
